<template>
  <section>
    <router-view />
  </section>
</template>

<script>
export default {
  name: 'RouteTransport'
}
</script>

<style lang="scss" scoped>

</style>
